import React from 'react';
import constructionImage from '../images/PhoneTabletDesktop.png';
import employeeIcon from '../images/employee.png';
import jobLocationIcon from '../images/JobLocation.png';
import equipmentIcon from '../images/Equipment.png';
import trainingIcon from '../images/Training.png';
import formsIcon from '../images/Forms.png';
import assetIcon from '../images/Asset.png';
import pepperLogo from '../images/companyLogos/Pepper-logo.gif';
import cumminsLogo from '../images/companyLogos/Cummins-black-logo-small.jpg';
import electricPlusLogo from '../images/companyLogos/ElectricPlus.png';
import sdiLogo from '../images/companyLogos/SDI_logo_RGB.jpg';
import sprigLogo from '../images/companyLogos/sprig-electric-logo.jpg';
import gribbinsLogo from '../images/companyLogos/gribbins-logo.jpg';
import haggermanLogo from '../images/companyLogos/haggerman-logo.png';
import androidLogo from '../images/android.png';
import number1 from '../images/number1.png';
import number2 from '../images/number2.png';
import number3 from '../images/number3.png';
import number4 from '../images/number4.png';
import number5 from '../images/number5.png';
import number6 from '../images/number6.png';
import pepperConstructionImage from '../images/companyLogos/Pepper_Construction_Digital.jpg';

import '../styles.css';
import '../inside.css';

function Home() {
  return (
    <div id="sectionContent">
      <div id="construction">
        <img id="constructionPicture" src={constructionImage} alt="Construction Visual" />
        <div id="constructionText">
          <h1>What is WorkerScan?</h1>
          <p>WorkerScan is a web-based service that brings accountability and control to your business. We make it easy to track employees, job site information, equipment, and training all from your smartphone, tablet, and desktop.</p>
        </div>
      </div>

      <div id="detailSection">
        <center>
          <DetailBox image={employeeIcon} title="Employees" text="Know the location of your employees, their emergency medical information, Union affiliation and equipment assigned to them" />
          <DetailBox image={jobLocationIcon} title="Job Location" text="Know the location of job sites, names of Supervisors and Safety Managers. See the assigned employees and equipment and site specific documents." />
          <DetailBox image={equipmentIcon} title="Equipment" text="Know the status of equipment, who's assigned to it, when it needs to be inspected, complete inspection and item history documentation." />
          <DetailBox image={trainingIcon} title="Training" text="Track and organize training documents, certifications, and renewals for employees. Standardize a single location for training material that is accessible anywhere." />
          <DetailBox image={formsIcon} title="Forms" text="Get rid of your paper forms. Duplicate your existing forms and execute them in the field. WorkerScan will automatically file and store them in the proper location in the cloud." />
          <DetailBox image={assetIcon} title="Large Equipment" text="Similar to equipment, you can also create custom forms, attach documents and document, account and report specific equipment expense." />
        </center>
      </div>

      <div id="redBox">
        <center>
          <h2>What Makes WorkerScan Unique?</h2>
          <InfoBox image={number1} text="WorkerScan does not need special equipment and is designed for everyday technology." />
          <InfoBox image={number2} text="WorkerScan creates a completely paperless environment. Access anything from anywhere at anytime." />
          <InfoBox image={number3} text="No special training needed to use WorkerScan effectively." />
          <InfoBox image={number4} text="WorkerScan's flexible user interface allows the creation of custom form fields and categories." />
          <InfoBox image={number5} text="WorkerScan can import your existing spreadsheet data for seamless integration." />
          <InfoBox image={number6} text="Utilize existing RFID technology already inserted into many items." />
        </center>
      </div>

      <div id="testimonialBox">
        <center>
          <h2>Read What People Are Saying About WorkerScan</h2>
          <Testimonial image={pepperConstructionImage} text="Worker Scan has proven to be a valuable addition to our safety management program... " name="Dave Murphy" title="Safety Director" company="Pepper Construction" />
        </center>
      </div>

      <div id="trialBox">
        <a href="https://play.google.com/store/apps/details?id=com.workerscan.android"><img id="android" src={androidLogo} alt="Android Store" /></a>
        <h2 style={{ display: 'inline' }}>Sign up for a free trial!</h2>
        <button style={{ display: 'inline' }}>Start Here</button>
      </div>

      <WhoUsesSection />
    </div>
  );
}

function DetailBox({ image, title, text }) {
  return (
    <div className="detailBox">
      <img src={image} alt={title} />
      <h3 id="smallTitle">{title}</h3>
      <p>{text}</p>
      <button>See how it works</button>
    </div>
  );
}

function InfoBox({ image, text }) {
  return (
    <div className="infoBox">
      <img src={image} alt="Feature Icon" />
      <p>{text}</p>
    </div>
  );
}

function Testimonial({ image, text, name, title, company }) {
  return (
    <div className="testimonialDetail">
      <img src={image} alt="Testimonial" width="150px" />
      <p>{text} <a href="testimonials.php">see more</a> </p>
      <h4>{name}<br />{title}<br />{company}</h4>
    </div>
  );
}

function WhoUsesSection() {
  return (
    <div>
      <center><h2>Who uses WorkerScan?</h2></center>
      <center>
        <img src={pepperLogo} alt="Pepper Construction" className="whoUsesImage" />
        <img src={cumminsLogo} alt="Cummins" className="whoUsesImage" />
        <img src={electricPlusLogo} alt="ElectricPlus" className="whoUsesImage" />
        <img src={sdiLogo} alt="Steel Dynamics INC" className="whoUsesImage" />
        <img src={sprigLogo} alt="Sprig Electric" className="whoUsesImage" />
        <img src={gribbinsLogo} alt="Gribbins Insulation" className="whoUsesImage" />
        <img src={haggermanLogo} alt="Haggerman Group" className="whoUsesImage" />
      </center>
    </div>
  );
}


export default Home;
