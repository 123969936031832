import React, { useContext, useEffect, useState } from 'react';
import logoDefault from '../images/logoDefault.png';
import sloganImage from '../images/slogan.png';
// import { UserContext } from './UserContext'; // Assuming you manage user state globally

function Header() {
  // const { user, setUser } = useContext(UserContext);
  // const [companyName, setCompanyName] = useState('');
  // const [welcomeName, setWelcomeName] = useState('');

  // useEffect(() => {
  //   if (user && user.subscriberID) {
  //     // Simulate fetching company name and user name
  //     fetchCompanyName(user.subscriberID);
  //     fetchUserName(user.userIDUniqueID);
  //   }
  // }, [user]);

  // const fetchCompanyName = async (subscriberID) => {
  //   // Placeholder for API call
  //   // Assume API returns JSON with company name
  //   const response = await fetch(`/api/subscriber/${subscriberID}`);
  //   const data = await response.json();
  //   setCompanyName(data.companyName);
  // };

  // const fetchUserName = async (userID) => {
  //   const response = await fetch(`/api/user/${userID}`);
  //   const data = await response.json();
  //   setWelcomeName(`Welcome ${data.firstName || user.userIDUniqueID}`);
  // };

  return (
    <header>
      <div id="top">
        <a href={"index.php"}>
          <img src={logoDefault} alt="Default Logo" title="Logo" height="149" />
        </a>
        <div id="topRight">
          
            <p id="loggedIn">
              <a href="https://app.workerscan.com" style={{ margin: '0 0 0 13px' }}>Please Login</a>
            </p>
        </div>
        <img src={sloganImage} alt="Slogan" height="21" id="slogan" />
      </div>

      <nav>
        <div id="nav">
          <p id="links">
            <a href="/">Home</a>
            <a href="/features">Features</a>
            <a href="/testimonials">Testimonials</a>
            <a href="/faq">FAQ</a>
            <a href="/contactUs">Contact Us</a>
          </p>
          {/* {user && window.location.pathname !== "/login.php" && (
            <div id="navRight">
              <form id="selectForm" action="search.php" method="post">
                <button type="submit" id="search">Search</button>
                <input type="text" id="searchBar" name="search" />
              </form>
            </div>
          )} */}
        </div>
      </nav>
    </header>
  );
}

export default Header;
