import React from 'react';
// import './Testimonials.css'; // This CSS file will contain the blockquote styles mentioned in the PHP file
import cumminsLogo from '../images/companyLogos/Cummins-black-logo-small.jpg';
import ryanLogo from '../images/companyLogos/Ryan Logo Master_full.jpeg';
import sdiLogo from '../images/companyLogos/SDI_logo_RGB.jpg';
import sprigLogo from '../images/companyLogos/sprig-electric-logo.jpg';
import gribbinsLogo from '../images/companyLogos/gribbins-logo.jpg';
import haggermanLogo from '../images/companyLogos/haggerman-logo.png';
import pepperLogo from '../images/companyLogos/Pepper-logo.gif';
import pepperConstructionImage from '../images/companyLogos/Pepper_Construction_Digital.jpg';


const Testimonials = () => {
    return (
        <div>
            <center><h1>Customer Testimonials</h1></center>
            <div style={{ float: 'left' }}>
                <center>
                    <img src={pepperConstructionImage} width="200px" alt="Pepper Construction" />
                    <h4><b>Dave Murphy - Safety Director<br />Pepper Construction</b></h4>
                </center>
            </div>
            <div style={{ float: 'right' }}>
                <blockquote>
                    <p>Worker Scan has proven to be a valuable addition to our safety management program. We are currently using three capabilities of the system – equipment inspection, training tracking, and safety forms.</p>
                    <br /><br />
                    <p>All of our fall protection equipment is tracked in Worker Scan for job location and inspection status. We are able to make sure that the fall protection in use by our employees has been inspected and documented. The training module has simplified our training records into one database that houses employee training records and certifications with notification to our administrator when training expires. Lastly, we have begun using the form capability of Worker Scan. All of our foremen now complete their daily Task Hazard Analysis on their smartphones using voice recognition. This shift to paperless is very popular with our foremen because of the ease of use and for the safety manager, you have real time information on what your crews are doing each day.</p>
                    <br /><br />
                    It has been a great experience working with John and the folks at Worker Scan moving our program forward to maximize the benefits of technology. They have worked closely with us to adapt their product to our needs. We plan on expanding our use of Worker Scan to further automate our data collection and analysis.
                </blockquote>
            </div>
            <div style={{ clear: 'both' }}></div>
            <br /><br />
            <center><h1>Who uses WorkerScan?</h1></center>
            <center>
                <img src={pepperLogo} className="whoUsesImage" alt="Pepper Construction Logo" />
                <img src={cumminsLogo} className="whoUsesImage" alt="Cummins Logo" />
                <img src={ryanLogo} className="whoUsesImage" alt="Ryan Fire Protection Logo" />
                <img src={sdiLogo} className="whoUsesImage" alt="Steel Dynamics INC Logo" />
                <img src={sprigLogo} className="whoUsesImage" alt="Sprg Electric Logo" />
                <img src={gribbinsLogo} className="whoUsesImage" alt="Gribbins Insulation Logo" />
                <img src={haggermanLogo} className="whoUsesImage" alt="Haggerman Group Logo" />
            </center>
        </div>
    );
}

export default Testimonials;
