import React, { useState } from 'react';

const ContactUs = () => {
    // const [formData, setFormData] = useState({
    //     name: '',
    //     email: '',
    //     phone: '',
    //     company: '',
    //     location: '',
    //     demo: false,
    //     comments: ''
    // });
    // const [message, setMessage] = useState('');

    // const handleChange = (e) => {
    //     const { name, value, type, checked } = e.target;
    //     setFormData(prev => ({
    //         ...prev,
    //         [name]: type === 'checkbox' ? checked : value
    //     }));
    // };

    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     if (!formData.name || !formData.email || !formData.phone) {
    //         setMessage('Please complete the form before hitting send. Must enter name, email, and phone.');
    //         return;
    //     }
    //     // Simulate sending the form data
    //     console.log(formData);
    //     setMessage('Message sent.');
    // };

    return (
        <div>
            <center><h1>Contact Us</h1>
            <div id="info">
                <center>
                    <p>If you would like more information about WorkerScan.com please contact John Gallagher or complete the form below</p>
                    <br />
                    Email: <a href="mailto:johng@workerscan.com">johng@workerscan.com</a><br />
                    Call: 317-752-1234
                </center>
            </div>
            </center>
            {/* {message && <div id="info" style={{ color: 'red' }}><center>{message}</center></div>}
            <form onSubmit={handleSubmit}>
                <fieldset>
                    <ul>
                        <li><label>Name*: </label><input type="text" name="name" value={formData.name} onChange={handleChange} /></li>
                        <li><label>Email Address*: </label><input type="text" name="email" value={formData.email} onChange={handleChange} /></li>
                        <li><label>Phone Number*: </label><input type="text" name="phone" value={formData.phone} onChange={handleChange} /></li>
                        <li><label>Business/Company: </label><input type="text" name="company" value={formData.company} onChange={handleChange} /></li>
                        <li><label>City/State: </label><input type="text" name="location" value={formData.location} onChange={handleChange} /></li>
                        <li><label>Interested in demo?: </label><input type="checkbox" name="demo" checked={formData.demo} onChange={handleChange} /></li>
                        <li><label>Comments: </label><textarea rows="4" cols="30" name="comments" value={formData.comments} onChange={handleChange}></textarea></li>
                    </ul>
                    <input type="submit" value="Send" />
                </fieldset>
            </form> */}
        </div>
    );
};

export default ContactUs;
