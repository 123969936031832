import React, { useState } from 'react';
import employeeIcon from '../images/employee.png';
import jobLocationIcon from '../images/JobLocation.png';
import equipmentIcon from '../images/Equipment.png';
import trainingIcon from '../images/Training.png';
import formsIcon from '../images/Forms.png';
import assetIcon from '../images/Asset.png';

const FAQ = () => {
    const [visibleAnswer, setVisibleAnswer] = useState(null);

    const questionsAnswers = [
        { title: "General", anchor: "General", qna: [
            { question: "What is WorkerScan?",
            answer: "WorkerScan is an all in one asset and employee management suite that provides instant access to your employees, their training, job location information, equipment location, and equipment assignment."},
            { question: "How do I sign up?",
            answer: "Signing up is simple. Click here and provide the information requested. We will contact you some information and help you with the account set up process. Or simply call us and we will be happy to personally assist you with account set up."},
            { question: "We have multiple locations, do I need to have seperate accounts?",
            answer: "No, WorkerScan is designed to manage small to large clients with multiple jobsites or locations."},
            { question: "What is our commitment, am I on a contract?",
            answer: "No, we are a monthly subscription that may be canceled at any time. We also give you the ability to export all information in WorkerScan."},
            { question: "Is there a set-up fee to get started?",
            answer: "No, additional fees are not added any time. We are currently offering free technical support to assist with set up and entering data into our system."},
            { question: "My current information is on spreadsheets can I import them into WorkerScan?",
            answer: "Yes, included with the first month, we will assist in importing all the data you have available. This can include Workers, Training, Equipment, and Job Sites."},
            { question: "Is WorkerScan customizable?",
            answer: "Yes, completely! WorkerScan was designed to work within your current structure. We don't force our terminology on you. We give you the ability to use your own terminology throughout WorkerScan."}
        ]},
        { title: "Worker", anchor: "Worker", qna: [
            { question: "Can I see a worker's complete profile from my smartphone or tablet in the field?",
            answer: "Yes, you'll have a complete history of each employee, their job sites, their equipment and training along with all documentation."},
            { question: "Do our employees need to have a card or tag to be identified?",
            answer: "No, you can use the search box to find employees by name (complete or partial) or use employee ID number."},
            { question: "Can we set up custom administrative rights for each employee to restrict what certain users can see and do within WorkerScan?",
            answer: "Yes, WorkerScan provides the ability to control what each person is able to do and see within WorkerScan."},
            { question: "I have workers that are in charge of equipment. Can they be granted access?",
            answer: "Yes. As long as the worker is in our system you can create a user account for that user free of charge. We also give you the ability to control how much or how little your users see, giving you complete control over your data privacy."}
        ]},
        { title: "Job Location", anchor: "JobLocation", qna: [
            { question: "I have a lot of job site documents that must be on hand at all times?",
            answer: "WorkerScan is perfect for you. Without a job location storage system, you can easily file away your important documents under easy to navigate categories and pull them up anytime, anywhere on a smartphone, tablet, or computer."}
        ]},
        { title: "Equipment", anchor: "Equipment", qna: [
            { question: "I already have all of my data in spreadsheet and in another system?",
            answer: "WorkerScan assist you with importing all of your current data whether it is kept in speadsheets, desktop systems, or other online systems."},
            { question: "I have existing tags and RFIDs on my equipment. Can WorkerScan utilize these tags?",
            answer: "WorkerScan can easily integrate with any barcode tags or embedded RFID tags."},
            { question: "Do I need Barcode or RFID tags to use WorkerScan?",
            answer: "No, WorkerScan is designed to work with the part number and serial number on your equipment. These combined numbers make that equipment unique. You can also use the search option to quickly look up the equipment."},
            { question: "If my equipment is not labeled can I add WorkerScan tags to manage the equipment?",
            answer: "Yes, WorkerScan offers non-destructible tags that can be affixed to your equipment and allows you to scan your equipment."}
        ]},
        { title: "Training", anchor: "Training", qna: [
            { question: "How many training topics can I add in WorkerScan?",
            answer: "Unlimited training topics can be added and managed in WorkerScan."},
            { question: "Can WorkerScan help with the management of my training topics and material?",
            answer: "Yes, WorkerScan provides a single portal for your employees in the field to obtain all training materials and presentations. You can also document the training all from the field."},
            { question: "In jobsite orientation, we train many people on many training topics. Is there a easy way to complete training and document many people at once?",
            answer: "Yes, we offer the ability to easily select your employees and the topics and with one stroke complete, assign, document the training and assign the renewal dates."},
            { question: "Can you help me with my training classes that I give my employees?",
            answer: "Of course we can. WorkerScan has several different features to help you with this; such as our bulk training feature that allows you to train multiple workers on multiple training topics at once. We also have what we call training packages, where you can bundle courses into one and then you train a worker on the package and they receive them all. We also have a document repository tied to each training where you can conveniently store any and all training materials needed for that course, so that they can be available anywhere and anytime"}
        ]},
        { title: "Forms", anchor: "Forms", qna: [
            { question: "How can we archive all of our forms if we go paperless? Can we still print?",
            answer: "WorkerScan, when you complete the forms, will auto archive them for you in our easy to navigate filesystem. We do give you the ability to download them at anytime and print or store them for your own personal backup needs."}
        ]},
        { title: "Large Equipment", anchor: "Asset", qna: [
            { question: "What is the main difference between large equipment and normal equipment.",
            answer: "Normal equipment includes things such as harnesses and small tools that you will inspect on a regular basis. WorkerScan keeps track of these inspection dates and can notify you as they come up. \
            Large Equipment, on the other hand, tends to be things more like trucks and large machinery. In our system you can track and report on expenditures that you may have for these pieces of equipment.You can also complete forms about them and store any pertinent documentation in the Large Equipment specific file."},
            { question: "Can I run reports on the Large Equipment that will provide the time it was assigned to a specific job site to help in internal billing?",
            answer: "Yes, our reports will show where the equipment is or and report on how long it was at a job site."}
        ]},

    ];

    const toggleAnswer = (index) => {
        setVisibleAnswer(visibleAnswer === index ? null : index);
    };

    return (
        <div>
            <div id="faqNav">
            <a href="#Worker"><img src={employeeIcon} id="navImage" />
                <h3>Workers</h3></a><br />
            <a href="#JobLocation"><img src={jobLocationIcon} id="navImage" />
                    <h3>Job Location</h3></a><br />
            <a href="#Equipment"><img src={equipmentIcon} id="navImage" />
                    <h3>Equipment</h3></a><br />
            <a href="#Training"><img src={trainingIcon} id="navImage" />
                    <h3>Training</h3></a><br />
            <a href="#Forms"><img src={formsIcon} id="navImage" />
                    <h3>Forms</h3></a><br />
            <a href="#Asset"><img src={assetIcon} id="navImage" />
                    <h3>Large Equipment</h3></a>
            </div>
            <div id="faqSection">
                <div id="titleFAQ">Frequently Asked Questions</div>
                {questionsAnswers.map((titledSection) => (
                    <div>
                    <h1>
                        <a name={titledSection.anchor}></a>
                        {titledSection.title}
                    </h1>
                    {titledSection.qna.map((qa, index) => (
                        <React.Fragment key={index}>
                            <div className="faqQuestions" onClick={() => toggleAnswer(index)}>
                                <h2>{qa.question}</h2>
                            </div>
                            <div className="faqAnswer show">
                                <p>{qa.answer}</p>
                            </div>
                        </React.Fragment>
                    ))}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FAQ;
