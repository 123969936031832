import React, { useState, useEffect } from 'react';
// import './Features.css'; // Assume styles are similar and imported from a CSS file
import employeeImage from '../images/employee.png';
import locationImage from '../images/JobLocation.png';
import equipmentImage from '../images/Equipment.png';
import trainingImage from '../images/Training.png';
import formsImage from '../images/Forms.png';
import assetImage from '../images/Asset.png';
import employeeScreenImage from '../images/EmployeeScreen.png';
import locationScreenImage from '../images/JobLocationScreen.png';
import equipmentScreenImage from '../images/EquipmentScreen.png';
import trainingScreenImage from '../images/TrainingScreen.png';
import formsScreenImage from '../images/FormsScreen.png';
import assetScreenImage from '../images/AssetScreen.png';



const Features = () => {
    const [activeFeature, setActiveFeature] = useState(null);

    useEffect(() => {
        const hash = window.location.hash.replace('#', '');
        setActiveFeature(hash);
    }, []);

    const handleFeatureClick = (feature) => {
        setActiveFeature(feature);
        window.location.hash = feature;
    };

    const features = [
        { id: 'Employee', image: employeeImage, screenImage: employeeScreenImage, title: 'Employees', 
            description: "On the worker's profile page, you can view all kinds of worker-specific information. There are tabs across the top that include things such as trainings with corresponding certificates and renewal dates, job locations, trades, equipment/assets, and any documents that need to be categorized and stored specifically about that worker. WorkerScan.com provides emergency contacts and any critical first responder information that needs to be communicated in the event of an emergency on the fly.", 
            specific_features: [
                {description: "Quickly create notes about the worker from the field"},
                {description: "See specific assigned tasks for the worker"}
            ]
        },
        { id: 'JobLocation', image: locationImage, screenImage: locationScreenImage, title: 'Job Location',
            description: "The job location page provides information about the job; including Foreman, Supervisor, and Safety Manager. It also is a place to securely store any documents so they can be retrieved and used anywhere both in the office and on the go. From this page, you can see tabs for all of the equipment, workers, and assets. You also have a running news feed as well as assigned tasks. You can also notify all job site workers by email or text with any important information like oncoming weather.", 
            specific_features: [
                {description: "WorkerScan can import all equipment and inspections seamlessly"},
                {description: "Create your own custom inspection checklists for any equipment"},
                {description: "Simple way to track equipment and its required inspections with reminders and notifications"},
                {description: "Manage unlimited pieces of equipment"}
            ]
        },
        { id: 'Equipment', image: equipmentImage, screenImage: equipmentScreenImage, title: 'Equipment',
            description: "This feature-rich section offers the ability to categorize your equipment in several levels to facilitate easy navigation and identification. WorkerScan.com gives you the ability to create and attach customizable safety inspections and complete them, on-demand. You can also assign equipment to workers or job locations to create accountability. Along with the basic information about your equipment, we also store a complete up-to-the-minute history of your equipment so you can track it back as far as it has been in WorkerScan.com. We also give you easy to view 'days until inspection' on all your equipment.", 
            specific_features: [
                {description: "Complete forms digitally and have them saved and archived in the appropriate job site file folders automatically"},
                {description: "See, add, and complete job specific tasks from the shared job site home page"}
            ]
        },
        { id: 'Training', image: trainingImage, screenImage: trainingScreenImage, title: 'Training',
            description: "Training is an attribute that you can assign and track for workers including how long until the training expires and when the renewal is due. WorkerScan.com allows you to categorize and store various training documents that may be needed during the training process. Once a worker is trained, WorkerScan.com will store any certification documents earned as proof of training and are conveniently visible under the worker's profile.", 
            specific_features: [
                {description: "Track and organize training documents, certifications and renewals for employees"},
                {description: "Standardize a single location for training material that is accessible anywhere"},
                {description: "Add unlimited training topics"}
            ]
        },
        { id: 'Forms', image: formsImage, screenImage: formsScreenImage, title: 'Forms',
            description: "The forms tab offers the do-it-yourself form creator where you can take existing paper forms and create digital ones. Once your forms are digitized, you can complete the form online with any device and submit them. WorkerScan.com will then automatically categorize and store them. This gives you an easy place to save all of your completed forms for job locations, workers, or assets in our easy to navigate categorized list that can be accessed anywhere.", 
            specific_features: [
                {description: "Customizable form creator to eliminate paper forms"},
                {description: "Complete forms about workers, job locations, or large equipment from anywhere."},
                {description: "Forms automatically save after completion of the form"}
            ]
        },
        { id: 'Asset', image: assetImage, screenImage: assetScreenImage, title: 'Large Equipment',
            description: "Large Equipment is similar to Equipment except for a few expanded features. Large equipment offers the ability to track categorized expenditures with convenient reports that breaks the expenditures down by the assigned job locations or workers. Assets also give you the ability to store categorized documents and complete any customized forms.", 
            specific_features: [
                {description: "Know how long the equipment was assigned to a specific location or person"},
                {description: "Save specific files to the equipment for retrieval anywhere"}
            ]
        }
    ];

    return (
        <div>
            <div id="construction">
                <img id="constructionPicture" src="../images/PhoneTabletDesktop.png" alt="Device Compatibility" />
                <div id="constructionText">
                    <h1>WorkerScan Features</h1>
                    <p>Try out each feature below in a live demo that mimics how WorkerScan operates on your smartphone, tablet, and desktop. Notice the easy to navigate lists and tabs that give you all your information right at your fingertips!</p>
                </div>
            </div>

            <div id="detailSectionFeature">
                <center>
                    {features.map((feature) => (
                        <div className="detailBoxFeature" key={feature.id} onClick={() => handleFeatureClick(feature.id)}>
                            <a href={`#${feature.id}`}>
                                <img src={feature.image} id={`${feature.id}Logo`} style={{ opacity: activeFeature === feature.id ? 1 : 0.5 }} alt={`${feature.title}`} />
                                <br />
                                <div id={`${feature.id}Text`}><h3 id="smallTitle">{feature.title}</h3></div>
                            </a>
                        </div>
                    ))}
                </center>
            </div>

            <hr style={{ height: '3px', color: '#B7B7B7', backgroundColor: '#B7B7B7', width: '85%', margin: '-10px auto 50px auto' }} />

            {features.map((feature) => (
                <div id={`${feature.id}Section`} style={{ display: activeFeature === feature.id ? 'block' : 'none' }}>
                    <div className="leftFeature">
                        <div className="leftFeatureText">
                            <img src={feature.image} alt={`${feature.title}`} />
                            <h2>{feature.title}</h2>
                            <p>{feature.description}</p>
                            <ul>
                                {feature.specific_features.map((specific_feature) => (
                                    <li>{specific_feature.description}</li>
                                ))}
                            </ul>
                            <hr />
                            <h3 style={{ display: 'inline' }}>Sign up for a free trial!</h3><a style={{ display: 'inline' }} href="contactUs.php"><button style={{ display: 'inline' }}>Start Here</button></a>
                        </div>
                    </div>
                    <div className="rightFeature">
                        <div className="ipadcontent">
                            <img src={feature.screenImage} alt={`${feature.title} Screen`} />
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Features;
