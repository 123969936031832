import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import Features from './components/Features';
import Testimonials from './components/Testimonials'
import FAQ from './components/FAQ'
import ContactUs from './components/ContactUs'

import './styles.css';
import './inside.css';

function App() {
  return (
    <Router>
      <Header />
      <div id="container">
        <section>
          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/features" element={<Features />} />
            <Route path="/testimonials" element={<Testimonials />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/contactUs" element={<ContactUs />} />
          </Routes>
        </section>
        <Footer />
      </div>
      
    </Router>
  );
}

export default App;